.button {
    display: inline-flex;

    justify-content: center;
    align-items: center;

    vertical-align: bottom;

    font-size: 2rem;
    width: 6rem;
    height: 9rem;

    border-style: solid;
    border-width: 1px;
    border-radius: 0.8rem;

    /* transition: transform 12s, font-size 10s ease-in-out, background-color 8s; */
    transition: transform 0.6s, font-size ease-in-out 0.5s, background-color 0.4s;

    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@media (hover: hover) {
    .button:hover {
        transform: translateY(-0.1em);
        cursor: pointer;
    }
}

.button:active {
    transform: rotate(60deg);
}
