body {
    margin: auto;
    padding: 1rem;
    max-width: 36rem;
}

body {
    font-family: 'EB Garamond';
    background-color: #E9EDC9;
}

* {
    box-sizing: border-box;
}
